.squad-card {
  background-image: url("../../images/squad-card.png");
  background-size: cover;
  border: 4px solid var(--brown);
  padding: 1rem;
  width: 12rem;
  box-shadow: 8px 8px 16px rgba(102, 70, 20, 0.32);

  .image {
    position: relative;

    .nft {
      width: 100%;
      background-color: var(--brown-light);
      border: 1px solid var(--brown);
    }

    .wanted {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      font-family: "Stardos Stencil", cursive;
      font-size: 2.5rem;
      font-weight: bold;
      transform: rotate(-45deg);
      text-transform: uppercase;
      color: var(--red);
    }
  }

  .separator {
    margin: 1rem 0;
  }

  .reward {
    text-align: center;
    font-family: "Pirata One", cursive;
  }

  .description {
    display: flex;
    flex-direction: column;

    .rank-and-name {
      display: flex;

      .rank {
        font-weight: bold;

        .diez {
          color: var(--red);
        }
      }

      .name {
        font-family: "Pirata One", cursive;
        padding-left: 1rem;

        a {
          color: var(--brown);

          &:hover {
            opacity: 0.7;
          }
        }
      }
    }

    .role {
      font-size: smaller;
    }
  }
}
