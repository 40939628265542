.separator {
  display: flex;
  align-items: center;
  gap: 2rem;

  .line {
    flex: 1;
    height: 0;
    border-bottom: 1px solid #a6957b;
  }

  .dots {
    display: flex;
    gap: 0.25rem;

    .dot {
      width: 3px;
      height: 3px;
      border-radius: 100%;
      background-color: var(--red);
    }
  }
}
