.home {
  width: 100%;
  flex: 1;

  .image {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: absolute;
    z-index: -2;
    width: 100%;
    height: 100%;
    bottom: 4rem;
    left: 0;
    background-image: url("../images/boat-mobile.png");
    background-position-x: center;
    background-position-y: bottom;
    background-repeat: no-repeat;
    background-size: auto 45%;

    @media screen and (min-width: 600px) {
      height: 80%;
      background-image: url("../images/boat.png");
      background-size: contain;
      bottom: 0;
    }
  }

  .mobile {
    @media screen and (min-width: 600px) {
      display: none;
    }

    .title {
      font-size: 4rem;
      color: var(--black);
      font-family: "Pirata One", cursive;
      text-align: center;

      span {
        color: var(--red);
      }
    }

    .roadmap {
      margin: 2rem auto;
    }
  }

  .roadmap {
    display: flex;
    justify-content: center;
    width: 60%;

    img {
      width: 100%;
    }
  }

  .desktop {
    position: absolute;
    display: flex;
    justify-content: center;
    left: 0;
    bottom: 1rem;
    width: 100%;
    display: none;

    .roadmap {
      margin: auto;
      width: 100%;
    }

    @media screen and (min-width: 600px) {
      display: flex;
    }
  }
}
