.squad {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  flex: 1;
  padding: 1rem;
  box-sizing: border-box;

  > .title {
    display: flex;
    align-items: center;
    gap: 2rem;
    width: 100%;

    .line {
      flex: 1;
      height: 0;
      border-bottom: 1px solid #a6957b;
    }

    .text {
      font-size: 3rem;
      font-family: "Pirata One", cursive;
      color: var(--black);

      @media screen and (min-width: 600px) {
        font-size: 4rem;
      }

      .red {
        color: var(--red);
      }
    }

    .logo {
      opacity: 0.8;
      height: 4rem;

      @media screen and (min-width: 600px) {
        height: 5rem;
      }
    }
  }

  .description {
    margin: 3rem 0;
    text-align: center;

    @media screen and (min-width: 600px) {
      width: 80%;
    }

    @media screen and (min-width: 1000px) {
      width: 50%;
    }

    .separator {
      width: 30%;
      min-width: 150px;
      margin: auto;
    }

    .title {
      text-transform: uppercase;
      text-align: center;
      font-weight: bold;
      font-size: 1.5rem;
      margin-bottom: 1rem;
    }
  }

  .cards {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    gap: 3rem;
    justify-content: center;
    margin-bottom: 2rem;
  }
}
