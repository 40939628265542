.app {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100%;

  @media screen and (min-width: 1024px) {
    width: 80%;
    margin: auto;
  }
}
