.roadmap {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  flex: 1;
  padding: 1rem;
  box-sizing: border-box;

  .title {
    display: flex;
    align-items: center;
    gap: 2rem;
    width: 100%;

    .line {
      flex: 1;
      height: 0;
      border-bottom: 1px solid #a6957b;
    }

    .text {
      font-size: 3rem;
      font-family: "Pirata One", cursive;
      color: var(--black);

      @media screen and (min-width: 600px) {
        font-size: 4rem;
      }

      .red {
        color: var(--red);
      }
    }

    .logo {
      opacity: 1;
      height: 4rem;

      @media screen and (min-width: 600px) {
        height: 5rem;
      }
    }
  }

  .intro {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    margin: 3rem 0;

    @media screen and (min-width: 1024px) {
      flex-direction: row;
    }

    .text {
      display: flex;
      flex: 1;
      flex-direction: column;

      .ahoy {
        font-weight: 700;
        font-style: italic;
        border-left: 1px solid var(--brown);
        padding-left: 1rem;
      }
    }

    .image {
      display: flex;
      flex: 1;
      align-items: center;

      img {
        border: 0.5rem solid var(--brown-light);
        border-radius: 1rem;
        overflow: hidden;
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto;
      }
    }
  }

  .separator {
    width: 80%;
  }

  .map {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
    margin-top: 2rem;
    width: 100%;

    @media screen and (min-width: 1024px) {
      grid-template-columns: 1fr 1fr;
    }

    .goal {
      background-color: var(--brown-extra-light);
      display: flex;
      border: 0.5rem solid var(--brown-light);
      border-radius: 1rem;
      overflow: hidden;

      .bars {
        $barSize: 10%;

        display: flex;
        width: 2rem;
        background: linear-gradient(
          45deg,
          var(--brown-light) $barSize,
          transparent $barSize,
          transparent 50% - $barSize,
          var(--brown-light) 50% - $barSize,
          var(--brown-light) 50% + $barSize,
          transparent 50% + $barSize,
          transparent 100% - $barSize,
          var(--brown-light) 100% - $barSize
        );
        background-size: 15px 15px;
      }

      .text {
        flex: 1;
        padding: 2rem;

        .title {
          font-weight: bold;
        }

        p {
          font-weight: 400;
          font-size: smaller;
        }
      }
    }
  }
}
