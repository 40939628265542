:root {
  --black: #080a18;
  --red: #9a3b3b;
  --brown: #664614;
  --brown-light: #d3c4ad;
  --brown-extra-light: #dbcfbd;
}

body {
  position: relative;
  height: 100%;
  width: 100%;
  background: radial-gradient(25% 16% at 35% 75%, #cab89b 0%, rgba(211, 200, 183, 0) 100%) fixed,
    radial-gradient(25% 16% at 82% 50%, #cab89b 0%, rgba(211, 200, 183, 0) 100%) fixed,
    radial-gradient(20% 13% at 12% 25%, #cab89b 0%, var(--brown-light) 100%) fixed;
  margin: 0;
  color: var(--black);
  font-size: 20px;
  font-family: "Raleway", sans-serif;

  @media screen and (min-width: 600px) {
    background: radial-gradient(20% 30% at 35% 75%, #cab89b 0%, rgba(211, 200, 183, 0) 100%) fixed,
      radial-gradient(27% 41% at 82% 50%, #cab89b 0%, rgba(211, 200, 183, 0) 100%) fixed,
      radial-gradient(22% 34% at 12% 25%, #cab89b 0%, var(--brown-light) 100%) fixed;
  }

  @media screen and (min-width: 1024px) {
    background: radial-gradient(10% 30% at 35% 75%, #cab89b 0%, rgba(211, 200, 183, 0) 100%) fixed,
      radial-gradient(17% 41% at 82% 50%, #cab89b 0%, rgba(211, 200, 183, 0) 100%) fixed,
      radial-gradient(12% 34% at 12% 25%, #cab89b 0%, var(--brown-light) 100%) fixed;
  }
}

button {
  font-family: "Raleway", sans-serif;
}

a {
  text-decoration: none;
}

.overflow-hidden {
  &,
  * {
    overflow: hidden;
  }
}
