.header {
  display: flex;
  gap: 1rem;
  box-sizing: border-box;
  padding: 2.5rem 1rem;
  width: 100%;
  justify-content: space-between;

  @media screen and (min-width: 600px) {
    align-items: center;
  }

  .menu {
    display: none;
    gap: 1rem;
    overflow: hidden;

    @media screen and (min-width: 600px) {
      display: flex;
      align-items: center;
    }

    @media screen and (max-width: 600px) {
      &.open {
        position: absolute;
        z-index: 10;
        display: flex;
        box-sizing: border-box;
        flex-direction: column;
        padding: 1rem;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: radial-gradient(19.72% 29.17% at 34.93% 77.17%, #d6c4a7 0%, rgba(211, 200, 183, 0) 100%) fixed,
          radial-gradient(27.85% 41.19% at 82.5% 59.39%, #d6c4a7 0%, rgba(211, 200, 183, 0) 100%) fixed,
          radial-gradient(22.64% 33.48% at 12.64% 24.56%, #d6c4a7 0%, var(--brown-light) 100%) fixed;
        font-size: x-large;

        .links {
          flex: 1;
          flex-direction: column;
          justify-content: center;
          gap: 0.5rem;
        }

        a {
          margin: 1rem 0;
          text-align: center;
        }
      }
    }

    .links {
      display: flex;
      gap: 2rem;
      align-items: center;
    }
  }

  .mobile-menu-button {
    display: flex;
    align-items: center;
    border: none;
    background-color: transparent;
    font-size: 1.5rem;
    color: var(--red);

    @media screen and (min-width: 600px) {
      display: none;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .close-mobile-menu {
    background-color: var(--red);
    border: none;
    color: white;
    font-size: large;
    height: 4rem;
    justify-content: center;
    cursor: pointer;

    @media screen and (min-width: 600px) {
      display: none;
    }
  }

  .bg {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    height: 133px;
    background-image: url("../../images/navbar.svg");
    background-repeat: repeat-x;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

    .lane-1 {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 1rem;
      height: 25px;

      background: linear-gradient(90deg, #ffce85 6.93%, #fff1bf 33.04%, #c39c62 91.79%);
    }

    .lane-2 {
      height: 6px;

      background: linear-gradient(90deg, #e3aa54 6.93%, #dac478 33.04%, #ad7f3b 91.79%);
    }

    .lane-3 {
      height: 6px;

      background: #a17320;
    }
  }

  a {
    color: var(--brown);
    font-weight: 600;
    cursor: pointer;

    &.active {
      color: var(--red);
    }

    &.disabled {
      cursor: not-allowed;
      opacity: 0.4;
    }

    &:not(.disabled):hover {
      opacity: 0.7;
    }
  }

  .logo {
    mix-blend-mode: soft-light;
    height: 50px;

    &:hover {
      mix-blend-mode: overlay;
    }

    &.mobile {
      height: 80px;
    }
  }

  .social {
    display: flex;
    gap: 1rem;

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.5rem;
      width: 1rem;
      font-size: smaller;

      &:hover {
        cursor: pointer;
      }
    }
  }
}
