.footer {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  box-sizing: border-box;
  padding: 1rem;
  width: 100%;
  background: #c2b59f;
  border-top: 1px solid #ac987a;

  @media screen and (min-width: 600px) {
    background: transparent;
    border-top: none;
  }

  .copyright {
    font-size: small;
    text-align: left;
  }
}
